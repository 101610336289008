import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import {Route} from "react-router-dom";
import configureStore, {history} from "./redux/store/store";
import {Provider} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
import axios from "axios";
import {API_URN_AUTH_STATUS} from "./constants/apiURNs";
import {autoLogin, fetchPatientMinimalDetailsRequest_saga, loginSuccess} from "./redux/actions/authActions";
import ReactGA from 'react-ga';
import {AUTHENTICATED_USER_TYPE} from "./constants/portalUserType";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

history.listen(location => {
    ReactGA.set({page: location.pathname}); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a page view for the given page
});

const store = configureStore();

axios.defaults.baseURL = process.env.REACT_APP_API_ROOT_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers = {
    "Content-Type": "application/json"
};
axios.defaults.headers.Accept = "application/hal+json";

//Check authenticated status using existing token. Sends cookie(httpOnly), if the response is not 2xx, user is not authenticated
axios({
    method: "GET",
    url: API_URN_AUTH_STATUS
}).then(({data}) => {
    const {isAdmin, isPatient, isSuperAdmin} = data;
    let role;
    if (isAdmin)
        role = AUTHENTICATED_USER_TYPE.ADMIN
    if (isPatient)
        role = AUTHENTICATED_USER_TYPE.PATIENT
    if (isSuperAdmin)
        role = AUTHENTICATED_USER_TYPE.SUPER_ADMIN
    store.dispatch(autoLogin(true));
    store.dispatch(loginSuccess(role));
    isPatient && store.dispatch(fetchPatientMinimalDetailsRequest_saga());
}).catch(() => {
    store.dispatch(autoLogin(false));
    //do nothing
})

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Route component={App}/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
