import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, capitalize, Card, CardContent, Collapse, Typography, useTheme} from "@mui/material";
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {TransitionGroup} from 'react-transition-group';

function MyMedications({data}) {
    const theme = useTheme();
    const [showItems, setShowItems] = useState(() => data?.length > 2 ? 2 : data?.length);

    return (
        <Box>
            <Box marginY={1}>
                <Typography variant={"h6"}>Medications {data?.length ? `(${data?.length})` : ""}</Typography>
            </Box>
            <Box>
                {data?.length > 0
                    ? <>
                        <TransitionGroup>
                            {data.slice(0, showItems).map((medication, i) => {
                                const {name, quantity, frequency, route} = medication;
                                return (
                                    <Collapse key={name + "-" + i}>
                                        <Box marginY={1} maxWidth={920}>
                                            <Card>
                                                <CardContent>
                                                    <Box padding={1}>
                                                        <Box flex={1}>
                                                            <Box marginY={1}>
                                                                <Typography component={"h5"} variant={"h5"}
                                                                            color={"primary"}>
                                                                    {capitalize(name ?? "")}
                                                                </Typography>
                                                            </Box>
                                                            {(quantity || frequency) &&
                                                            <Box marginY={1}>
                                                                <Typography variant={"subtitle2"}
                                                                            color={"textSecondary"}>
                                                                    {quantity}
                                                                    {(quantity && frequency) && ", "}
                                                                    {frequency}
                                                                </Typography>
                                                            </Box>
                                                            }
                                                            {route &&
                                                            <Box>
                                                                <Typography color={"textSecondary"}
                                                                            variant={"subtitle1"} fontWeight={500}>
                                                                    {route}
                                                                </Typography>
                                                            </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Collapse>
                                )
                            })}
                        </TransitionGroup>
                        {data?.length > 2 &&
                        <Box textAlign={"center"}>
                            <Button color={"inherit"} endIcon={showItems > 2 ? <ArrowUpIcon/> : <ArrowDownIcon/>}
                                    className={"_showMore"}
                                    onClick={() => showItems === data?.length ? setShowItems(2) : setShowItems(data?.length)}>
                                {showItems > 2 ? "Show Less" : `Show All ${data.length} Medications`}
                            </Button>
                        </Box>
                        }
                    </>
                    : <Box>
                        <Card>
                            <CardContent>
                                <Typography color={"error"}>
                                    There are currently no medications assigned to you. If this is incorrect, please
                                    call Nufactor technical support at 1-800-323-6832 to update your profile with a team
                                    member.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </Box>
        </Box>
    );
}

MyMedications.propTypes =
    {
        data: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            quantity: PropTypes.string,
            frequency: PropTypes.string,
            route: PropTypes.string,
            comments: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string
        }))
    }
;

export default MyMedications;