import axios from "axios";
import {API_INFUSION_JOURNAL} from "../constants/apiURNs";

export function fetchJournals() {
    return axios.get(API_INFUSION_JOURNAL)
}


export function addOrUpdateJournal(data,action) {
    console.log(action);
    if(action === "SAVE"){
        return axios.post(API_INFUSION_JOURNAL, data)
    }else if (action === "SAVEANDSENDCSS"){
        return axios.post(API_INFUSION_JOURNAL+"/save-and-send", data)
    }/*else if (action === "UPDATE"){
        return axios.put(API_INFUSION_JOURNAL+"/save-and-send", data)
    }*/
}