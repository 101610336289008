import { call , put , select , takeLatest } from "redux-saga/effects";
import { PRESCRIPTION_SUPPLIES } from "../actions/ActionTypes";
import { fetchAllPrescriptions , fetchAllSupplies } from "../../api/prescriptionsSupplies";
import { pageErrorExtractor } from "../../utils/httpErrorHelpers";
import { add , isAfter } from "date-fns";
import { setCacheExpiryTime , setData , setError , setLoading } from "../actions/prescriptionAndSuppliesActions";
import { AUTHENTICATED_USER_TYPE } from "../../constants/portalUserType";

function* handleFetchAllPrescriptionsAndSupplies (){
    const {currentPatientUsername, role} = yield select(state => state?.auth);
    const { error, cacheExpiryTime } = yield select((state) => state.prescriptionsSupplies);
    const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));

  if (role === AUTHENTICATED_USER_TYPE.ADMIN || error || cacheExpiryTime === null || isCacheExpired) {
    try {
        yield put(setError(null));
        yield put(setLoading(true));
        const prescriptionsResponse = yield call(fetchAllPrescriptions,currentPatientUsername);//get All prescriptions
        console.log(prescriptionsResponse);
        const suppliesResponse = yield call(fetchAllSupplies,currentPatientUsername);///get All prescriptions
        console.log(prescriptionsResponse);
        // console.log(suppliesResponse);
        const prescriptionData =  prescriptionsResponse.data;
        const suppliesData = suppliesResponse.data;
        const data={prescriptionData,suppliesData};
        console.log(data);
        yield put(setData(data));
        yield put(setLoading(false));
        //Set cache expiry time. Cache is valid for 2 hours
        yield put(setCacheExpiryTime(add(new Date(), { hours: 2 }).toString()));
      } catch (error) {
        yield put(setError(pageErrorExtractor(error)));
        yield put(setLoading(false));
      }
  }

}

function* prescriptionsSuppliesSaga() {
    yield takeLatest(PRESCRIPTION_SUPPLIES.FETCH_ALL_PRESCRIPTION_SUPPLIES, handleFetchAllPrescriptionsAndSupplies);

  }
  export default prescriptionsSuppliesSaga;