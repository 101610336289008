/**
 * A helper function to override the default style of Form fields like Select, Input and Date picker
 * @param {boolean} error if field in error state
 * @param {Object} theme MUI theme object
 */
export function formFieldStyleHelper(error, theme) {
    return {
        '& .MuiFilledInput-root': {
            border: `1px solid ${error ? theme.palette.error.main : theme.palette.divider}`,
            overflow: 'hidden',
            borderRadius: 1,
            backgroundColor: "transparent",
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            '&:hover': {
                backgroundColor: 'transparent',
                borderColor: theme.palette.action.active
            },
            '&:before': {
                content: "none"
            },
            '&:after': {
                content: "none"
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                // boxShadow: error ? "none" : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
                borderColor: error ? theme.palette.error.main : theme.palette.secondary.main,
                borderWidth: "1px"
            },
        },
    }
}