import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    styled,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import {PageContentWrapper} from "../../styles/page";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import axios from "axios";
import {toast} from "react-toastify";
import {differenceInYears, format, parseISO} from "date-fns"
import {pageErrorExtractor} from "../../../utils/httpErrorHelpers";
import EditIcon from '@mui/icons-material/Edit';
import AvatarEditor from "../../components/AvatarEditor";
import {API_URN_DISPLAY_PICTURE, API_URN_PATIENTS, API_URN_PROFILE} from "../../../constants/apiURNs";
import {bytesToFile} from "../../../utils/file";
import {fetchFileBytes} from "../../../api/media";
import {setAvatarUrl} from "../../../redux/actions/headerActions";
import {useDispatch, useSelector} from "react-redux";
import {HEADER} from "../../../redux/actions/ActionTypes";
import MainContentModal from "../../components/MainContentModal";
import ProfileEditForm from "./ProfileEditForm";
import withLoaderAndErrorCard from "../../../hoc/withLoaderAndErrorCard";
import {AUTHENTICATED_USER_TYPE} from "../../../constants/portalUserType";

const LightTooltip = withStyles((theme) => (
    {
        tooltip: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            boxShadow: theme.shadows[1],
            lineHeight: 1.2,
            fontSize: 14,
            padding: 10
        },
        arrow: {
            color: theme.palette.secondary.main,
        }
    }
))(Tooltip);

const useStyles = makeStyles((theme) => (
    {
        profileEditButton: {
            marginRight: -130,
            marginTop: -35,
            background: theme.palette.background.default,
        },
        borderedAvatar: {
            borderWidth: 2,
            borderColor: theme.palette.secondary.main,
            borderBlockStyle: "solid",
            height: 120,
            width: 120
        }
    }
))

const ProfileBasicWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ._details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    ._name {
      text-transform: capitalize;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`;

const ContentWrapper = styled("div")`
  width: 100%;

  ._container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: .5rem;

    > * {
      flex: 1;
    }

    ${({theme}) => (
            {
              [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                gap: "1rem"
              }
            }
    )}
  }
`;

const BadgeSpan = styled("span")`
  position: absolute;
  left: 0;
  top: -20px;
  cursor: pointer;

  ._warn {
    color: #FFCC00;
  }
`;

export default function Profile() {
    const theme = useTheme();
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [displayPictureUrl, setDisplayPictureUrl] = useState(null);
    const [displayPictureBytes, setDisplayPictureBytes] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [isAvatarEditorOpen, openAvatarEditor] = useState(false);
    const [isFetchingProfileImage, setFetchingProfileImage] = useState(false);

    const {currentPatientUsername, role} = useSelector(state => state?.auth);
    const isPatientRole = role === AUTHENTICATED_USER_TYPE.PATIENT;
    const dispatchAction = useDispatch();

    const {
        firstname,
        lastname,
        dob,
        contactEmail,
        addresses,
        phoneHome,
        phoneWork,
        phoneCell,
        _links
    } = user ?? {};
    const {updateProfile} = _links ?? {};

    useEffect(() => {
        fetchUserProfile();
    }, [currentPatientUsername]);

    useEffect(() => {
        if (displayPictureUrl) {
            getImageBytes(displayPictureUrl);
        }
    }, [displayPictureUrl])

    const fetchUserProfile = () => {
        if (currentPatientUsername) {
            setLoading(true);
            setError(null);
            axios({
                url: API_URN_PATIENTS + "/" + currentPatientUsername + API_URN_PROFILE
            }).then(({data}) => {
                setUser(data);
                setDisplayPictureUrl(data?.displayPictureUrl);
                setLoading(false);
            }).catch(error => {
                setError(pageErrorExtractor(error))
                setLoading(false);
            })
        }
    }

    const handleProfileUpdateSuccess = (updatedData) => {
        setUser(updatedData);
        setEdit(false);
        toast('Details Updated', {autoClose: 2500, type: "success"});
    }

    /**
     * @param {object} data {displayPictureUrl, avatarUrl}
     */
    const handleAvatarChange = (data) => {
        const {displayPictureUrl} = data ?? {};
        openAvatarEditor(false);
        setDisplayPictureUrl(displayPictureUrl);
        saveImageToDb(data);
    }

    /**
     * Save the image URL to database table
     * @param {object} images {displayPictureUrl, avatarUrl}
     */
    const saveImageToDb = (images) => {
        axios.patch(API_URN_PATIENTS + "/" + currentPatientUsername + API_URN_PROFILE + API_URN_DISPLAY_PICTURE, images)
            .then(() => {
                const {displayPictureUrl, avatarUrl} = images ?? {};
                getImageBytes(displayPictureUrl);
                dispatchAction(setAvatarUrl(avatarUrl));
                dispatchAction({type: HEADER.REFRESH_AVATAR, payload: {refreshAvatar: true}})
            })
            .catch(() => {
                toast.warn("Something went wrong, please try refreshing the page")
            })
    }

    /**
     * The imageURL is a GCS's gsutil URI which is not public. Backend need to respond with the file bytes using this
     * file location
     * @param imageURL the gsutil URI
     */
    const getImageBytes = (imageURL) => {
        if (imageURL) {
            imageURL = encodeURIComponent(imageURL);
            setFetchingProfileImage(true);
            fetchFileBytes(imageURL)
                .then(({data}) => {
                    const {bytes} = data;
                    setDisplayPictureBytes("data:image/png;base64, " + bytes);
                    setFetchingProfileImage(false);
                })
                .catch(() => {
                    setFetchingProfileImage(false);
                    toast.warn("Failed to fetch your display picture");
                })
        } else {
            setDisplayPictureBytes(null);
        }
    }

    const contentJsx = () => (
        <Box width={"100%"} maxWidth={"1200px"}>
            {/*<div className={"_page_actions"}>*/}
            {/*    <Button variant={"outlined"} color={"primary"} startIcon={<Edit/>}*/}
            {/*            onClick={() => setEdit(true)}>*/}
            {/*        EDIT*/}
            {/*    </Button>*/}
            {/*</div>*/}
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} md={6}>
                    <Card sx={{minHeight: "210px", "&:hover": {boxShadow: theme.shadows[3]}}}>
                        <CardContent>
                            <ProfileBasicWrapper>
                                <Avatar
                                    src={displayPictureBytes}
                                    alt={"avatar image"}
                                    className={classes.borderedAvatar}
                                />
                                {isPatientRole &&
                                <Tooltip title={"Add Your Profile Picture"}>
                                    <Button className={classes.profileEditButton} size={"small"}
                                            variant={"outlined"}
                                            onClick={() => openAvatarEditor(true)}
                                            startIcon={isFetchingProfileImage
                                                ? <CircularProgress color={"primary"} size={20}/>
                                                : <EditIcon color={"primary"} fontSize={"small"}/>
                                            }
                                    >
                                        Edit
                                    </Button>
                                </Tooltip>
                                }
                                <div className="_details _capitalize">
                                    <Typography variant={"h5"}>
                                        {`${firstname} ${lastname}`}
                                    </Typography>
                                </div>
                            </ProfileBasicWrapper>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        [`${theme.breakpoints.up("md")}`]: {
                            display: "none"
                        }
                    }}>
                        <Typography variant={"h6"}>Personal Information</Typography>
                    </Box>
                    <Card sx={{
                        overflow: "auto", "&:hover": {boxShadow: theme.shadows[3]},
                        [`${theme.breakpoints.up("md")}`]: {
                            minHeight: 210
                        }
                    }}>
                        <CardContent>
                            <ContentWrapper>
                                <Box className={"_container"}>
                                    <div className={"_item"}>
                                        <Typography color={"textSecondary"}>
                                            First Name
                                        </Typography>
                                        <Typography className={"_capitalize"}>{firstname}</Typography>
                                    </div>
                                    <div className={"_item"}>
                                        <Typography color={"textSecondary"}>
                                            Last Name
                                        </Typography>
                                        <Typography className={"_capitalize"}>{lastname}</Typography>
                                    </div>
                                </Box>
                                <Box className={"_container"} marginTop={1}>
                                    <div>
                                        <Typography color={"textSecondary"}>DOB</Typography>
                                        <Typography>{format(parseISO(dob), "MMM' 'd' ,'yyyy")}</Typography>
                                    </div>
                                    <div>
                                        <Typography color={"textSecondary"}>Age</Typography>
                                        <Typography>{differenceInYears(new Date(), parseISO(dob))}yrs</Typography>
                                    </div>
                                </Box>
                            </ContentWrapper>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Box marginBottom={1}>
                        <Typography variant={"h6"}>Contact Information</Typography>
                    </Box>
                    <Card sx={{"&:hover": {boxShadow: theme.shadows[3]}}}>
                        <CardContent>
                            <ContentWrapper>
                                <Box className={"_container"}>
                                    <div>
                                        <Typography color={"textSecondary"}>
                                            Phone(Primary)
                                        </Typography>
                                        <Typography>{phoneCell}</Typography>
                                    </div>
                                    <div>
                                        <Typography color={"textSecondary"}>Phone(Home)</Typography>
                                        <Typography>{phoneHome}</Typography>
                                    </div>
                                    <div>
                                        <Typography color={"textSecondary"}>Phone(Work)</Typography>
                                        <Typography>{phoneWork}</Typography>
                                    </div>
                                    <div>
                                        <Typography color={"textSecondary"}>Email</Typography>
                                        <Typography>{contactEmail}</Typography>
                                    </div>
                                </Box>
                            </ContentWrapper>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {addresses?.sort(a => a.isPrimary === true ? -1 : 1)
                        .map((address, i) => (
                            <React.Fragment key={i + "address"}>
                                {address.addressType === "Demographic" && <Box key={i + "address"}>
                                    <Typography variant={"h6"}>Demographic Address</Typography>
                                </Box>
                                }
                                {address.addressType === "Shipping" && <Box key={i + "address"}>
                                    <Typography variant={"h6"}>Shipping Address</Typography>
                                </Box>
                                }

                                <Card sx={{
                                    "&:hover": {boxShadow: theme.shadows[3]},
                                    "maxHeight": 150,
                                    "marginBottom": 1
                                }}>
                                    <CardContent>
                                        <ContentWrapper>
                                            <Box className={"_container"} position={"relative"}>
                                                <BadgeSpan>
                                                    {address.isPrimary &&
                                                    <LightTooltip
                                                        arrow
                                                        title={<Typography>
                                                            Primary address. Your orders will be delivered to this
                                                            address.
                                                        </Typography>}
                                                    >
                                                        <Typography variant={"caption"} color={"primary"}>
                                                            (Primary Address)
                                                        </Typography>
                                                    </LightTooltip>
                                                    }
                                                </BadgeSpan>
                                                <div>
                                                    <Typography color={"textSecondary"}>
                                                        Street Address
                                                    </Typography>
                                                    <Typography>{address.street}</Typography>
                                                </div>
                                                <div>
                                                    <Typography color={"textSecondary"}>City</Typography>
                                                    <Typography>{address.city}</Typography>
                                                </div>
                                                <div>
                                                    <Typography color={"textSecondary"}>State</Typography>
                                                    <Typography>{address.state}</Typography>
                                                </div>
                                                <div>
                                                    <Typography color={"textSecondary"}>Zip Code</Typography>
                                                    <Typography>{address.zip}</Typography>
                                                </div>
                                            </Box>


                                        </ContentWrapper>
                                    </CardContent>
                                </Card>
                            </React.Fragment>
                        ))}
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <PageContentWrapper>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    isLoading, error, onReloadClick: fetchUserProfile
                })
                : null
            }

            {isEdit &&
            <MainContentModal isOpen={isEdit} onClose={() => setEdit(false)}>
                <ProfileEditForm
                    apiURL={updateProfile?.href} data={user}
                    onClose={() => setEdit(false)}
                    onSuccess={handleProfileUpdateSuccess}/>
            </MainContentModal>
            }

            {isAvatarEditorOpen &&
            <AvatarEditor isOpen={isAvatarEditorOpen} onClose={() => openAvatarEditor(false)}
                          imageExisting={bytesToFile(displayPictureBytes, "display-picture.jpeg")}
                          onSubmit={handleAvatarChange}/>
            }
        </PageContentWrapper>
    );
}
