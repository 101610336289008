import React, { useEffect } from 'react';
import { PageContentWrapper } from '../../styles/page';
import { useDispatch, useSelector } from 'react-redux';
import withLoaderAndErrorCard from '../../../hoc/withLoaderAndErrorCard';
import { MESSAGE_CENTER } from '../../../redux/actions/ActionTypes';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import MessageCard from './MessageCard';
import AddIcon from '@mui/icons-material/Add';
import useScrollToTop from '../../../hooks/useScrollToTop';

function MessageCenterPage() {
    useScrollToTop();
    const theme = useTheme();
    const { isLoading, error, data } = useSelector(state => state?.messageCenter);

    const { currentPatientUsername } = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();

    useEffect(() => {
        currentPatientUsername && fetchMessages();
    }, [currentPatientUsername]);

    const fetchMessages = () => {
        dispatchAction({ type: MESSAGE_CENTER.FETCH_ALL });
    };

    const contentJsx = () => (
        data?.length > 0
            ? <Stack spacing={2} width={'100%'} bgcolor={'background.paper'} boxShadow={theme.shadows[1]}
                     border={`1px solid ${theme.palette.divider}`} paddingY={2}>
                {data?.sort((a, b) => new Date(a?.createdAt) > new Date(b?.createdAt) ? -1 : 1)
                    .map((message, i) => {
                        const { id, subject, body, createdAt, direction, isRead, careTeamMemberName, role } = message;
                        return (
                            <React.Fragment key={`message-center-cards-${id}`}>
                                <MessageCard
                                    messageId={id}
                                    content={body}
                                    direction={direction}
                                    createdAt={createdAt}
                                    messageSubject={subject}
                                    team={role} isRead={isRead}
                                    careTeamMemberName={careTeamMemberName}
                                />
                                {i + 1 < data?.length &&
                                <Divider />
                                }
                            </React.Fragment>
                        );
                    })}
            </Stack>
            : <Box
                sx={{
                    background: theme.palette.background.paper,
                    boxShadow: theme.shadows[1],
                    width: '100%',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiSvgIcon-root': { fontSize: '60px' },
                }}>
                <Box>
                    <AddIcon />
                </Box>
                <Box marginY={2}>
                    <Typography color={'textSecondary'} fontSize={'1.2rem'}>
                        You don't have any messages to date
                    </Typography>
                </Box>
                <Box marginY={2}>
                    <Typography color={'textSecondary'}>
                        Messages are started on the My Care Team page within the patient portal and are distributed
                        to the appropriate party.
                    </Typography>
                </Box>
            </Box>
    );

    return (
        <PageContentWrapper>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} maxWidth={800}>
                <Box textAlign={'left'} width={'100%'}>
                    <Typography variant={'h6'} component={'h2'} sx={{ marginBottom: 2 }}>Message Center</Typography>
                </Box>

                {currentPatientUsername
                    ? withLoaderAndErrorCard(contentJsx)({
                        isLoading,
                        error,
                        onReloadClick: fetchMessages,
                    })
                    : null
                }
            </Box>
        </PageContentWrapper>
    );
}

export default MessageCenterPage;