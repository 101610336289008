import { call, put, select, takeLatest } from 'redux-saga/effects';
import { MESSAGE_CENTER } from '../actions/ActionTypes';
import { add } from 'date-fns';
import { pageErrorExtractor } from '../../utils/httpErrorHelpers';
import {
    markMessagesRead,
    markMessagesReadToStore,
    setCacheExpiryTime,
    setError,
    setLoading,
    setMessageCenterMessages,
} from '../actions/messageCenterActions';
import { fetchMessageCenterMessages, markMessagesReadApi } from '../../api/careTeam';
import { setUnreadMessageCount } from '../actions/headerActions';


function* handleFetchMessageCenterMessages() {
    const { currentPatientUsername } = yield select(state => state?.auth);
    // const {error, cacheExpiryTime, data} = yield select(state => state.messageCenter);
    // const isCacheExpired = isAfter(new Date(), new Date(cacheExpiryTime));
    // if (error || (cacheExpiryTime === null) || isCacheExpired || data?.length === 0) {
    try {
        yield put(setError(null));
        yield put(setLoading(true));
        const response = yield call(fetchMessageCenterMessages, currentPatientUsername);
        const data = yield response.data;
        yield put(setMessageCenterMessages(data));
        yield put(setLoading(false));
        //Set cache expiry time. Cache is valid for 2 hours
        yield put(setCacheExpiryTime(add(new Date(), { minutes: 5 }).toString())); //5minutes cache expiry
    } catch (error) {
        yield put(setError(pageErrorExtractor(error)));
        yield put(setLoading(false));
    }
    // }
}

function* setMessagesRead(action) {
    const { currentPatientUsername } = yield select(state => state?.auth);
    const { unreadMessageCount } = yield select(state => state?.header);
    const { messageIds } = action?.payload;
    try {
        yield call(markMessagesReadApi, currentPatientUsername, messageIds);
        yield put(markMessagesReadToStore(messageIds));
        //Decrement the count from header badge
        let newUnreadMessageCount = unreadMessageCount - messageIds?.length;
        if (newUnreadMessageCount < 0)
            newUnreadMessageCount = 0;
        yield put(setUnreadMessageCount(newUnreadMessageCount));
    } catch (error) {
        yield put(markMessagesRead(messageIds));
    }
}

function* messageCenterSaga() {
    yield takeLatest(MESSAGE_CENTER.FETCH_ALL, handleFetchMessageCenterMessages);
    yield takeLatest(MESSAGE_CENTER.MARK_MESSAGES_READ, setMessagesRead);
}

export default messageCenterSaga;