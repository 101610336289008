import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const PrescriptionCardsWrapper = styled(TransitionGroup)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  ._cardWrapper {
    width: 100%;
  }

  ${({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
      "& ._cardWrapper": {
        width: 300,
      },
    },
  })}
`;

function PrescriptionTileBoard({ data }) {
  const theme = useTheme();
  const [showItems, setShowItems] = useState(() =>
    data?.length > 3 ? 3 : data?.length
  );

  return (
    <Box>
      <Box marginY={1}>
        <Typography variant={"h6"}>
          Prescriptions {data?.length ? `(${data?.length})` : ""}
        </Typography>
      </Box>
      <Box>
        {data?.length > 0 ? (
          <>
            <PrescriptionCardsWrapper>
              {data
                .sort((a, b) => (a.rxDescription < b.rxDescription ? -1 : 1))
                .slice(0, showItems)
                .map((condition, i) => {
                  const { rxDescription, trackingNumber, dateFilled, status,rxNumber} = condition;
                  return (
                    <Collapse key={rxDescription + "-" + i} className={"_cardWrapper"}>
                      <Card>
                        <CardContent>
                          <Box marginY={1}>
                            <Typography
                              component={"h6"}
                              variant={"h6"}
                              color={"primary"}
                            >
                              {rxDescription}
                            </Typography>
                            <Divider sx={{ marginY: 2 }} />
                          </Box>
                          <Box marginY={1}>
                            <Typography
                              color={"textSecondary"}
                              fontSize={"small"}
                            >
                              RX Number : {rxNumber}
                            </Typography>
                          </Box>
                          <Box marginY={1}>
                            <Typography
                              color={"textSecondary"}
                              fontSize={"small"}
                            >
                              Date Filled : {dateFilled}
                            </Typography>
                          </Box>
                          <Box marginY={1}>
                            <Typography
                              color={"textSecondary"}
                              fontSize={"small"}
                            >
                              Status : {status}
                            </Typography>
                          </Box>
                          <Box marginY={1}>
                            <Typography
                              onClick={() => console.log("====")}
                              color={"textSecondary"}
                              fontSize={"small"}
                            >
                              Tracking Number :{" "}
                              <a
                                href="https://www.fedex.com/en-in/tracking.html"
                                target={"_blank"}
                                rel={"noreferrer"}
                              >
                                {trackingNumber}
                              </a>
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Collapse>
                  );
                })}
            </PrescriptionCardsWrapper>
            {data?.length > 3 && (
              <Box textAlign={"center"}>
                <Button
                  color={"inherit"}
                  className={"_showMore"}
                  endIcon={showItems > 3 ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  onClick={() =>
                    showItems === data?.length
                      ? setShowItems(3)
                      : setShowItems(data?.length)
                  }
                >
                  {showItems > 3
                    ? "Show Less"
                    : `Show All ${data.length} Prescriptions`}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box>
            <Card>
              <CardContent>
                <Typography color={"error"}>
                  There are currently no prescriptions assigned to you. If this
                  is incorrect, please call Nufactor technical support at
                  1-800-323-6832 to update your profile with a team member.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
}

PrescriptionTileBoard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rxDescription: PropTypes.string
    })
  ),
};


export default PrescriptionTileBoard;
