import {PERSONAL_CONTACTS, RESET} from "../actions/ActionTypes";

const defaultState = {
    isLoading: false,
    error: null, //object
    cacheExpiryTime: null,
    pcdata: {
        emergencyContacts: [],
        nonEmergencyContacts: []
    }
}

export const personalContactsReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case PERSONAL_CONTACTS.SET_LOADING: {
            console.log(PERSONAL_CONTACTS.SET_LOADING)
            const {isLoading} = payload;
            return {...state, isLoading}
        }

        case PERSONAL_CONTACTS.SET_PAGE_ERROR: {
            const {pcerror} = payload;
            return {...state, pcerror}
        }

        case PERSONAL_CONTACTS.SET_CACHE_EXPIRY_TIME: {
            const {cacheExpiryTime} = payload;
            return {...state, cacheExpiryTime}
        }
        case PERSONAL_CONTACTS.SET_DATA: {
            const {pcdata} = payload;
            return {...state, pcdata}
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state};
    }
}