import React from 'react';
import {PageContentWrapper} from "../../styles/page";
import styled from "styled-components";
import useScrollToTop from "../../../hooks/useScrollToTop";

const url = 'https://www.nufactor.com/patients/index.html';

const IframeModalWrapper = styled.div`
  background-color: white;
  height: 95%;
  overflow-y: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 680px) {
    margin: 1rem;
    height: calc(100vh - 2rem);
  }
`;

export default function HealthWellness() {
    useScrollToTop();

    return (
        <PageContentWrapper>
            <IframeModalWrapper>
                <iframe src={url} frameBorder={"0"} title={url}/>
            </IframeModalWrapper>
        </PageContentWrapper>
    );
} 