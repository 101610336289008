import React, {useEffect, useState} from 'react';
import {PageContentWrapper} from "../../styles/page";
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {useTheme} from "@emotion/react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {AUTHENTICATED_USER_TYPE} from "../../../constants/portalUserType";
import {Redirect} from "react-router-dom";
import {URN_UN_AUTHORIZED} from "../../../constants/clientURNs";
import {useSelector} from "react-redux";
import NewAdminForm from "./NewAdminForm";
import AdminCard from "./AdminCard";
import axios from "axios";
import withLoaderAndErrorCard from "../../../hoc/withLoaderAndErrorCard";
import {pageErrorExtractor} from "../../../utils/httpErrorHelpers";
import {toast} from "react-toastify";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function SuperAdminHomePage() {
    useScrollToTop();
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);
    const [isLoadingAdmins, setLoadingAdmins] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState(null);
    const [changingStatusOfAdmin, setChangingStatusOfAdmin] = useState(null); //set the userId of operating user
    const [adminToDelete, setAdminToDelete] = useState(null);
    const [isDeletingAdmin, setDeletingAdmin] = useState(null); //set the userId of operating user
    const {role} = useSelector(state => state?.auth);

    useEffect(() => {
        if (tabValue === 1 && admins?.length < 1)
            fetchAdmins();
    }, [tabValue])

    if (role !== "UNSET" && role !== AUTHENTICATED_USER_TYPE.SUPER_ADMIN)
        return <Redirect to={URN_UN_AUTHORIZED}/>

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const fetchAdmins = () => {
        setLoadingAdmins(true);
        const url = process.env.REACT_APP_API_IDP_ROOT_URL + "/admins"
        axios.get(url, {withCredentials: true})
            .then(({data}) => {
                setAdmins(data);
                setLoadingAdmins(false);
            })
            .catch(error => {
                setError(pageErrorExtractor(error));
                setLoadingAdmins(false);
            })
    }

    /**
     * What to do after the admin got created
     */
    const handleNewAdminSuccess = () => {
        setAdmins([]);
        setTabValue(1);
    }

    const handleAdminAccountStatusChange = ({userId, enabled}) => {
        if (userId && enabled !== undefined) {
            setChangingStatusOfAdmin(userId);
            const url = process.env.REACT_APP_API_IDP_ROOT_URL + "/admins/" + userId + "/enabled";
            axios.put(url, {enabled}, {withCredentials: true})
                .then(() => {
                    const updated = admins.map(admin => {
                        if (admin?.userId === userId)
                            return {...admin, activeStatus: enabled}
                        else
                            return admin
                    })
                    setAdmins(updated);
                    setChangingStatusOfAdmin(null);
                })
                .catch(error => {
                    console.error(error);
                    setChangingStatusOfAdmin(null);
                    toast.error("Failed to enable/disable the account, please again.");
                })
        } else {
            console.error("Data not provided from AdminCard component");
            console.table({userId, enabled});
            toast.error("Cannot perform operation, please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922.")
        }
    }

    const handleAdminDelete = () => {
        if (adminToDelete) {
            const {userId, name} = adminToDelete ?? {};
            setDeletingAdmin(true);
            const url = process.env.REACT_APP_API_IDP_ROOT_URL + "/admins/" + userId;
            axios.delete(url, {withCredentials: true})
                .then(() => {
                    setAdmins(admins?.filter(admin => admin?.userId !== userId));
                    toast.success(`Admin account for ${name} has been deleted.`);
                    setDeletingAdmin(false);
                    setAdminToDelete(null);
                })
                .catch(error => {
                    console.log(error);
                    toast.error("Failed to delete admin, please try again");
                    setDeletingAdmin(false);
                })
        } else {
            console.error("Cannot delete admin. Data not provided to function: ", adminToDelete);
            toast.error("Cannot delete admin, please call Nufactor Technical Support at 1.800.323.6832 or Fax to 1.855.553.6922.")
        }
    }

    return (
        <PageContentWrapper>
            <Typography variant={"h5"} gutterBottom>Super Admin Home</Typography>
            <AppBar
                position="static"
                sx={{
                    maxWidth: 600,
                    background: theme.palette.background.paper,
                    zIndex: theme.zIndex.appBar,
                }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs"
                >
                    <Tab label="Create New Admin"/>
                    <Tab label="Show All Admins"/>
                </Tabs>
            </AppBar>
            <Box maxWidth={1000} marginY={2}>
                <TabPanel value={tabValue} index={0}>
                    <Box bgcolor={"background.paper"} marginY={2} padding={2}>
                        <NewAdminForm onSuccess={handleNewAdminSuccess}/>
                    </Box>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    {!isLoadingAdmins && admins?.length < 1 &&
                    <Box textAlign={"center"} padding={2}>
                        <Typography variant={"h5"} color={"textSecondary"}>No Admins found</Typography>
                    </Box>
                    }
                    {withLoaderAndErrorCard(() => (
                        <Box display={"flex"} gap={2} flexDirection={"column"}>
                            {admins?.sort((a, b) => a.name.split(" ")[0] < b.name.split(" ")[0] ? -1 : 1)
                                ?.map((admin) =>
                                    <Box key={admin?.username} flex={1}>
                                        <AdminCard
                                            data={admin}
                                            handleActiveStatus={handleAdminAccountStatusChange}
                                            handleDelete={setAdminToDelete}
                                            isOperatingOnStatus={changingStatusOfAdmin === admin?.userId}
                                            isDeletingAccount={isDeletingAdmin}
                                        />
                                    </Box>
                                )}
                        </Box>
                    ))({isLoading: isLoadingAdmins, error, onReloadClick: fetchAdmins})}
                </TabPanel>
            </Box>

            {adminToDelete &&
            <Dialog open={true}>
                <DialogContent>
                    <Typography>
                        This is a permanent account deletion which cannot be rolled back. Are you sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2}>
                        <Button variant={"outlined"} disabled={isDeletingAdmin}
                                onClick={() => setAdminToDelete(null)}>
                            Cancel
                        </Button>
                        <Button variant={"contained"} color={"error"}
                                onClick={handleAdminDelete} disabled={isDeletingAdmin}
                                endIcon={isDeletingAdmin && <CircularProgress size={20} color={"inherit"}/>}>
                            Delete
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            }
        </PageContentWrapper>
    );
}

export default SuperAdminHomePage;