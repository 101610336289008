import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {PageContentWrapper} from "../../styles/page";
import {useDispatch, useSelector} from "react-redux";
import NoInsuranceCard from "./NoInsuranceCard";
import {INSURANCE} from "../../../redux/actions/ActionTypes";
import useScrollToTop from "../../../hooks/useScrollToTop";
import withLoaderAndErrorCard from "../../../hoc/withLoaderAndErrorCard";
import InsuranceCard from "./InsuranceCard";

export default function Insurance() {
    useScrollToTop();
    const dispatchAction = useDispatch();
    const {currentPatientUsername} = useSelector(state => state?.auth)
    const {isLoading, error, data} = useSelector(state => state?.insurance);

    const {id, policyNumber} = data ?? {};

    useEffect(() => {
        currentPatientUsername && fetchInsurances();
    }, [currentPatientUsername])

    const fetchInsurances = () => {
        dispatchAction({type: INSURANCE.FETCH_ALL})
    }

    const contentJsx = () => (
        <Box>
            {(!id && !policyNumber)
                ? <Box display={"flex"} justifyContent={"center"}>
                    <NoInsuranceCard/>
                </Box>
                : <Box>
                    <InsuranceCard data={data}/>
                </Box>
            }
        </Box>
    )

    return (
        <PageContentWrapper>
            {currentPatientUsername
                ? withLoaderAndErrorCard(contentJsx)({
                    isLoading, error, onReloadClick: fetchInsurances
                })
                : null
            }
        </PageContentWrapper>

    );
}