import React from 'react';
import {Alert, Box, Button, Card, CardContent, CircularProgress, Stack, Typography, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import DeleteIcon from '@mui/icons-material/DeleteForever';
import WarningIcon from '@mui/icons-material/Warning';
import ActivateIcon from '@mui/icons-material/ToggleOn';

function AdminCard({data, handleActiveStatus, handleDelete, isOperatingOnStatus}) {
    const {userId, name, email, phone, username, activeStatus} = data ?? {};
    const theme = useTheme();

    return (
        <Card sx={{"&:hover": {boxShadow: theme.shadows[3]}}}>
            <CardContent>
                {!activeStatus &&
                <Alert severity={"warning"} sx={{marginBottom: 1, width: "fit-content"}}>
                    Account is disabled. {name} cannot login using these credentials.
                </Alert>
                }
                <Stack spacing={1}>
                    <Box display={"flex"}>
                        <Typography fontWeight={500} color={"textSecondary"}>Name:&nbsp;&nbsp;</Typography>
                        <Typography sx={{textTransform: "capitalize"}}>{name}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography fontWeight={500} color={"textSecondary"}>Username:&nbsp;&nbsp;</Typography>
                        <Typography>{username}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography fontWeight={500} color={"textSecondary"}>Email:&nbsp;&nbsp;</Typography>
                        <Typography>{email}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography fontWeight={500} color={"textSecondary"}>Phone:&nbsp;&nbsp;</Typography>
                        <Typography>{phone}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Typography fontWeight={500} color={"textSecondary"}>Account Status:&nbsp;&nbsp;</Typography>
                        <Typography>{activeStatus ? "ENABLED" : "DISABLED"}</Typography>
                    </Box>
                    <Box display={"flex"}>
                        <Stack direction={"row"} spacing={2}>
                            <Button color={activeStatus ? "warning" : "primary"} variant={"outlined"}
                                    startIcon={isOperatingOnStatus
                                        ? <CircularProgress size={20} color={"inherit"}/>
                                        : activeStatus ? <WarningIcon/> : <ActivateIcon/>
                                    }
                                    disabled={isOperatingOnStatus}
                                    onClick={() => handleActiveStatus({userId, enabled: !activeStatus})}>
                                {activeStatus ? "Disable Account" : "Enable Account"}
                            </Button>
                            <Button color={"error"} startIcon={<DeleteIcon/>} variant={"outlined"}
                                    onClick={() => handleDelete(data)}>
                                Delete Account
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}

AdminCard.propTypes = {
    data: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string,
        activeStatus: PropTypes.bool,
    }),
    handleActiveStatus: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isOperatingOnStatus: PropTypes.bool,
    isDeletingAccount: PropTypes.bool
}

export default AdminCard;