//A file which will hold all all the action types. Since these are static values, it's good to keep them in a separate file
export const AUTH = Object.freeze({
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    AUTO_LOGIN: "AUTO_LOGIN",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    FETCH_MINIMAL_DETAILS_SAGA: "GET_USER_MINIMAL_DETAILS_SAGA",
    FETCH_MINIMAL_DETAILS_SUCCESS: "GET_USER_MINIMAL_DETAILS_SUCCESS",
    SET_ACTIVE_PATIENT_DETAILS: "SET_ACTIVE_PATIENT_DETAILS"
})

export const IDP = Object.freeze(({
    REQUEST_SUCCESS_SAGA: "REQUEST_SUCCESS_SAGA"
}))

export const HEADER = Object.freeze(({
    SET_AVATAR_URL: "HEADER_SET_AVATAR_URL",
    SET_AVATAR_URL_BYTES: "HEADER_SET_AVATAR_URL_BYTES",
    REFRESH_AVATAR: "HEADER_REFRESH_AVATAR",
    SET_UNREAD_MESSAGE_COUNT: "SET_CARE_TEAM_UNREAD_MESSAGE_COUNT"
}))

export const RESULTS = Object.freeze(({
    FETCH_RECENT_HISTORY_SUCCESS: "FETCH_RECENT_HISTORY_SUCCESS",
    LOAD_MORE_RECENT_HISTORY_SUCCESS: "LOAD_MORE_RECENT_HISTORY_SUCCESS",

    FETCH_HISTORY_SUCCESS: "FETCH_HISTORY_SUCCESS",
    LOAD_MORE_HISTORY_SUCCESS: "LOAD_MORE_HISTORY_SUCCESS",
    SET_LOAD_MORE_HISTORY_API_URL: "SET_LOAD_MORE_HISTORY_API_URL",
    SET_LOAD_MORE_RECENT_API_URL: "SET_LOAD_MORE_RECENT_API_URL",
}))

export const SITE_SETTINGS = Object.freeze({
    HEALTH_SNAPSHOT_BANNER_VISIBLE: "HEALTH_SNAPSHOT_BANNER_VISIBLE"
})

export const INSURANCE = Object.freeze({
    FETCH_ALL: "FETCH_ALL_INSURANCE",
    SET_LOADING: "SET_INSURANCE_LOADING",
    SET_PAGE_ERROR: "SET_INSURANCES_PAGE_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_INSURANCE_CACHE_EXPIRY_TIME",
    SET_DATA: "SET_INSURANCE_DATA"
})

export const CARE_TEAM = Object.freeze({
    FETCH_ALL: "FETCH_ALL_CARE_TEAM_MEMBERS",
    SET_LOADING: "SET_CARE_TEAM_LOADING",
    SET_PAGE_ERROR: "SET_PAGE_CARE_TEAM_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_CARE_TEAM_CACHE_EXPIRY_TIME",
    SET_DATA: "SET_CARE_TEAM_DATA",

});

export const PERSONAL_CONTACTS = Object.freeze({
    SET_LOADING: "SET_PC_LOADING",
    SET_PAGE_ERROR: "SET_PAGE_PC_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_PC_CACHE_EXPIRY_TIME",
    FETCH_ALL: "FETCH_ALL_PERSONAL_CONTACTS",
    SET_DATA : "SET_PC_DATA"
});

export const MESSAGE_CENTER = Object.freeze({
    FETCH_ALL: "FETCH_ALL_MESSAGE_CENTER",
    SET_LOADING: "SET_MESSAGE_CENTER_LOADING",
    SET_PAGE_ERROR: "SET_MESSAGE_CENTER_PAGE_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_MESSAGE_CENTER_CACHE_EXPIRY_TIME",
    SET_DATA: "SET_MESSAGE_CENTER_DATA",
    MARK_MESSAGES_READ: "MARK_MESSAGES_READ",
    SET_MESSAGES_READ: "SET_MESSAGES_READ",
});

export const HEALTH_SUMMARY = Object.freeze({
    FETCH_ALL: "FETCH_ALL_HEALTH_SUMMARY",
    SET_LOADING: "SET_HEALTH_SUMMARY_LOADING",
    SET_PAGE_ERROR: "SET_HEALTH_SUMMARY_PAGE_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_HEALTH_SUMMARY_CACHE_EXPIRY_TIME",
    SET_DATA: "SET_HEALTH_SUMMARY_DATA"
});
export const PRESCRIPTION_SUPPLIES = Object.freeze({
    FETCH_ALL_PRESCRIPTION_SUPPLIES: "FETCH_ALL_PRESCRIPTION_SUPPLIES",
    SET_LOADING: "SET_PRESCRIPTION_SUPPLIES_LOADING",
    SET_PRESCRIPTION_SUPPLIES_PAGE_ERROR: "SET_PRESCRIPTION_SUPPLIES_PAGE_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_PRESCRIPTION_SUPPLIES_CACHE_EXPIRY_TIME",
    SET_DATA : "SET_PRESCRIPTION_SUPPLIES_DATA"
});
export const INFUSION_JOURNAL = Object.freeze({
    FETCH_ALL: "FETCH_ALL_INFUSION_JOURNAL_DATA",
    SET_LOADING: "SET_INFUSION_JOURNAL_LOADING",
    SET_PAGE_ERROR: "SET_PAGE_INFUSION_JOURNAL_ERROR",
    SET_CACHE_EXPIRY_TIME: "SET_INFUSION_JOURNAL_CACHE_EXPIRY_TIME",
    SET_DATA: "SET_INFUSION_JOURNAL_DATA",
    SET_INFUSION_JOURNAL_NEW_DATA: "SET_INFUSION_JOURNAL_NEW_DATA",
});

export const LIVE_UPDATES = Object.freeze({
    SUBSCRIBE_CARE_TEAM_MESSAGE_UNREAD: "SUBSCRIBE_CARE_TEAM_MESSAGE_UNREAD"
})

export const RESET = Object.freeze("RESET");