import React, {useEffect, useState} from "react";
import {Box, Stack,} from "@mui/material";
import {PageContentWrapper} from "../../styles/page";
import {useDispatch, useSelector} from "react-redux";
import withLoaderAndErrorCard from "../../../hoc/withLoaderAndErrorCard";
import PrescriptionTileBoard from "./PrescriptionTileBoard";
import SuppliesTileBoard from "./SuppliesTileBoard";
import {PRESCRIPTION_SUPPLIES} from "../../../redux/actions/ActionTypes";
import useScrollToTop from "../../../hooks/useScrollToTop";

function Prescriptions() {
    const {currentPatientUsername} = useSelector(state => state?.auth);
    const dispatchAction = useDispatch();
    const {isLoading, error, data} = useSelector(state => state?.prescriptionsSupplies);
    const {prescriptionData, suppliesData} = data;
    const [viewHowToUse, setViewHowToUse] = useState(false);
    const fetchPrescriptionsAndSupplies = () => {
        dispatchAction({type: PRESCRIPTION_SUPPLIES.FETCH_ALL_PRESCRIPTION_SUPPLIES});
    };

    useScrollToTop();

    useEffect(() => {
        currentPatientUsername && fetchPrescriptionsAndSupplies();
    }, [currentPatientUsername]);

    const [howToUseLinkToOpen, setHowToUseLinkToOpen] = useState(null);
    /*const prescriptionData = [
      { rxDescription: "Acetaminophen",rxNumber:12345678, trackingNumber:123456789012345,status:"Active",lastRefillDate:"12/17/2021" },
      { rxDescription: "Entresto",rxNumber:345677, trackingNumber:234567890123451,status:"Active",lastRefillDate:"12/08/2021" },
      { rxDescription: "Farxiga", rxNumber:789012,trackingNumber:345678901234512,status:"Active",lastRefillDate:"12/02/2021" },
      { rxDescription: "Fentanyl", rxNumber:456732,trackingNumber:456789012345123,status:"Pending",lastRefillDate:"12/01/2021" },
      { rxDescription: "Fentanyl Patch", rxNumber:34567,trackingNumber:567890123451234,status:"Pending",lastRefillDate:"12/05/2021" },
      { rxDescription: "Gabapentin",rxNumber:6789, trackingNumber:678901234512345,status:"Active",lastRefillDate:"12/08/2021" },
      { rxDescription: "Brilinta",rxNumber:345678, trackingNumber:789012345123456,status:"Pending",lastRefillDate:"12/09/2021" },
      { rxDescription: "Bunavail",rxNumber:234567, trackingNumber:890123451234567,status:"Pending",lastRefillDate:"12/12/2021" },
      { rxDescription: "Ciprofloxacin",rxNumber:2345678, trackingNumber:901234512345678,status:"Pending",lastRefillDate:"12/13/2021" },
      { rxDescription: "Zubsolv",rxNumber:876543, trackingNumber:12345123456789,status:"Active",lastRefillDate:"12/16/2021" },
    ];
    const suppliesData = [
      { name: "Aviva Control Solution", quantity:2.0,quantityOnOrder:2 },
      { name: "Compact Plus Clear Control Solution", quantity:1.5,quantityOnOrder:3 },
      { name: "SmartView Normal Control Solution", quantity:3.0,quantityOnOrder:4 },
      { name: " Cholesterol Control Solution", quantity:1.5,quantityOnOrder:4 },
      { name: "Glucose Control Solution", quantity:1.5,quantityOnOrder:4 },
      { name: "Agamatrix High Control Solution", quantity:2.5,quantityOnOrder:3 },
      { name: "Agamatrix Normal Control Solution", quantity:2.5,quantityOnOrder:4 },
      { name: "Dose Control Solution", quantity:1.5,quantityOnOrder:3},
      { name: "Control Solution, Low", quantity:2.0,quantityOnOrder:3 },

    ];*/

    const contentJsx = () => (
        <Stack spacing={4} maxWidth={1000} sx={{"& .MuiButton-root._showMore": {color: "text.secondary"}}}>
            <Box>
                <PrescriptionTileBoard data={prescriptionData}/>
            </Box>
            <Box>
                <SuppliesTileBoard data={suppliesData}/>
            </Box>
        </Stack>
    );
    return (
        <PageContentWrapper>
            <Box display={"flex"} flexDirection={"column"}  width={"100%"} maxWidth={1000}>
                {currentPatientUsername
                    ? withLoaderAndErrorCard(contentJsx)({
                        isLoading,
                        error,
                        onReloadClick: fetchPrescriptionsAndSupplies
                    })
                    : null
                }
            </Box>
        </PageContentWrapper>
    );
}

Prescriptions.propTypes = {};

export default Prescriptions;
