import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, styled,Box } from "@mui/material";

const PersonContactWrapper = styled("div")`
  ._personnelContactWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 500px;
  }
`;
function PersonContactsCard(props) {
  const { firstName, lastName, phone, email, relationship} = props;

  return (
    <PersonContactWrapper>
      <Card  className={"_personnelContactWrapper"} sx={{ m: 1, p: 1 }}>
        <CardContent sx={{ width: 200 }}>
          <Box 
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"right"}
            alignItems={"right"}
          >
            <Typography variant={"h6"} component={"h6"}>
              Name
            </Typography>
            <Typography variant={"subtitle1"}>
              {firstName} {lastName}
            </Typography>
            <Typography variant={"h6"} component={"h6"}>
              Phone
            </Typography>
            <Typography variant={"subtitle1"}> {phone}</Typography>
          </Box>
        </CardContent>
        <CardContent>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"right"}
            alignItems={"right"}
          >
            <Typography variant={"h6"} component={"h6"}>
              Relation
            </Typography>
            <Typography variant={"subtitle1"}> {relationship}</Typography>
            <Typography variant={"h6"} component={"h6"}>              
              Email
            </Typography>
            <Typography variant={"subtitle1"}> {email}</Typography>
          </Box>
        </CardContent>
      </Card>
    </PersonContactWrapper>
  );
}

PersonContactsCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
};

export default PersonContactsCard;
