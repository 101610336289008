import React from 'react';
import ErrorCard from "../ui/components/ErrorCard";
import {Box, CircularProgress} from "@mui/material";

/**
 * An higher order function to render three states of data.
 * 1. Loading
 * 2. Error
 * 3. Actual data
 * @param render the JSX to render if not loading and no error
 * @returns {function({isLoading: boolean, error: Object, onReloadClick: function}): JSX.Element|*}
 */
export default function withLoaderAndErrorCard(render) {
    return ({isLoading, error, onReloadClick}) => {
        return (
            isLoading
                ? <Box minHeight={200} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress color={"primary"} size={30}/>
                </Box>
                : error
                    ? <Box display={"flex"} justifyContent={"center"}>
                        <ErrorCard title={error.title} description={error.description} status={error.status}
                                   onReloadClick={onReloadClick}/>
                    </Box>
                    : render()
        )
    }
}

withLoaderAndErrorCard.propTypes = {};