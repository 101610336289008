import {PRESCRIPTION_SUPPLIES, RESET} from "../actions/ActionTypes";

const defaultState = {
    isLoading: false,
    error: null, //object
    data: {
        prescriptions: [],
        supplies: []
    },
    cacheExpiryTime: null
}

export const prescriptionAndSuppliesReducer = (state = defaultState, action) => {
    const {type, payload} = action;
    switch (type) {
        case PRESCRIPTION_SUPPLIES.SET_DATA: {
            const {data} = payload;
            return {...state, data}
        }
        case PRESCRIPTION_SUPPLIES.SET_LOADING: {
            const {isLoading} = payload;
            return {...state, isLoading}
        }

        case PRESCRIPTION_SUPPLIES.SET_PAGE_ERROR: {
            const {error} = payload;
            return {...state, error}
        }

        case PRESCRIPTION_SUPPLIES.SET_CACHE_EXPIRY_TIME: {
            const {cacheExpiryTime} = payload;
            return {...state, cacheExpiryTime}
        }

        case RESET: {
            return {...defaultState}
        }

        default:
            return {...state};
    }
}